import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import OrderList, { FilterStatusTypes } from "../Components/OrderList";
import * as Theme from "../Theme";
import ProfileNavbar, {
  entries,
  ProfileInfo,
  BreadCrumb,
  TabContainer,
} from "../Components/ProfileNavbar";
import UnAuthResult from "../Components/UnAuthResult";

const ENTRY_NAME = "monthly_manage";
const ENTRY = entries.find(e => e.name === ENTRY_NAME) || {};

class MonthlyManagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: ENTRY.entries[0],
    };
  }

  render() {
    const { profile } = this.props;
    const { selectedEntry } = this.state;

    return (
      <Wrapper>
        <div className="center-content">
          {!profile ? (
            <UnAuthResult />
          ) : (
            <Ant.Row>
              <Ant.Col md={3} xs={24}>
                <ProfileNavbar />
              </Ant.Col>
              <Ant.Col md={1} xs={24} style={{ height: 20 }} />
              <Ant.Col md={20} xs={24}>
                <BreadCrumb entry={ENTRY} leafNode={selectedEntry} />
                <ProfileInfo />
                <TabContainer
                  entry={ENTRY}
                  leafNode={selectedEntry}
                  onTabClick={entry =>
                    this.setState({
                      selectedEntry: entry,
                    })
                  }
                >
                  {selectedEntry.name === "monthlyRecord" && (
                    <OrderList type={FilterStatusTypes.MONTHLY} />
                  )}
                </TabContainer>
              </Ant.Col>
            </Ant.Row>
          )}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 40px;
  background-color: white;
  @media screen and (max-width: ${Theme.breakpoints.sm}px) {
    padding: 20px 0px;
  }

  .center-content {
    max-width: ${Theme.centerContentMaxWidth}px;
    margin: 0 auto;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(MonthlyManagePage);
